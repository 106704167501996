@import url("https://fonts.cdnfonts.com/css/clash-display");
@import url("https://fonts.cdnfonts.com/css/circular-std");
@import url("https://fonts.cdnfonts.com/css/cabinet-grotesk");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&family=Jost:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,500;0,600;0,700;0,800;1,100;1,200&family=Playfair+Display:ital@1&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Space+Grotesk:wght@400;500;600;700&family=Syne:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Stick+No+Bills:wght@200..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.inter {
  font-family: "Inter", sans-serif;
}
body {
  background-color: #000000;
  overflow-x: hidden;
}
.topp {
  background: radial-gradient(
    163.01% 160.82% at 52.92% 167.2%,
    #000000 77.8%,
    #262629 99.14%
  );

  border-radius: 50% 50% 0 0/ 100% 100% 0 0;
}

.gradient-01 {
  background: linear-gradient(
    -150deg,
    #15bffd00 0%,
    #15bffd00 26%,
    hsla(257, 69%, 86%, 0) 39%,
    rgba(141, 0, 206, 0) 40%,
    #ff008400 59%,

    rgba(255, 0, 131, 0.5) 60%,
    rgba(141, 0, 206, 0.5) 80%,
    rgba(141, 0, 206, 0.5) 100%
  );

  filter: blur(100px);
}

.ts-bg {
  background: rgba(255, 255, 255, 0.034);
  mix-blend-mode: luminosity;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(150.95704650878906px);
}

.bg-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa00 -43.27%,
    #bef3f500 -21.24%,
    #9dedf000 12.19%,
    #8d00ce 29.82%,
    rgba(255, 0, 132) 51.94%,
    rgba(255, 0, 132, 0.5) 90.29%
  );
  filter: blur(100px);
}

@property --bg-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}

@keyframes spin {
  to {
    --bg-angle: 360deg;
  }
}

.btn {
  animation: spin 2.5s infinite linear;

  background: linear-gradient(
        to bottom,
        rgba(47, 40, 91, 0.95),
        rgba(47, 40, 91, 0.95)
      )
      padding-box,
    /* ends at inner border edges */
      conic-gradient(
        from var(--bg-angle) in oklch longer hue,
        rgba(191, 36, 36, 1) 0 0
      )
      border-box; /* extends to outer border edges */

  border: 2px solid transparent;

  &:hover {
    animation-play-state: running;
  }
}

.btn {
  box-shadow: 0.125rem 0.25rem 0.25rem 0.5rem oklch(0.1 0.37 315 / 0.25);
}

.image_wrapper {
  margin: 20px 30px;
  background-color: transparent;
  width: 150px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}
.image_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.mq {
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
}

.ttt {
  width: 866px;
  -webkit-text-stroke: 2px #ea8ae0;
  -webkit-text-fill-color: white;
  font-family: Orbitron;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: linear-gradient(
    159deg,
    #fff 13.97%,
    rgba(255, 255, 255, 0) 129.72%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.geo {
  background: linear-gradient(
    96deg,
    #558f26 17.03%,
    rgba(240, 240, 240, 0.76) 53.65%,
    #548f26 89.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero {
  position: relative;
  background-image: url(./modules/demo1/assets/hero-bg.png);
  background-position: center;
  background-size: 1000px;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(
    0,
    0,
    0,
    0.932
  ); /* Adjust the '0.5' to control the darkness */
}
.navbar {
  background: #00000070;
}

.features {
  position: relative;
  background-image: url(./modules/demo1/assets/features.png);
  background-position: top;
  background-size: cover;
}

.features::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(
    0,
    3,
    1,
    0.795
  ); /* Adjust the '0.5' to control the darkness */
}

.cards {
  background: #2a2b2b52;
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 30px 20px;
}

.toks {
  background: #2a2b2b52;
  backdrop-filter: blur(10px);
  border-radius: 16px;
}
.int {
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #5aa70d;
  color: white;
}



.featured {
  background: url(../src/modules/demo3/assets/featured.svg) lightgray 50% / cover no-repeat;
  mix-blend-mode: lighten;
}

.tokenemics {
  background: url(../src/modules/demo3/assets/tokenemics.png) lightgray 50% / cover no-repeat;
  mix-blend-mode: lighten;
}

.community {
  background: url(../src/modules/demo3/assets/community.png) lightgray 50% / cover no-repeat;
}

.rgba {
  background-color: rgba(164, 164, 164, 0.1);
}

.accordion-item {
  box-shadow: 0px 4px 4px 0px rgba(35, 40, 105, 0.08);
  margin-bottom: 1rem;
  border-radius: 0.625rem;
  border: 1.5px solid #e4e4e7;
  background: #fff;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.accordion-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Shadow 1 */
  padding: 1.94rem 2.44rem 1.62rem;
}

.accordion-title h4 {
  color: #18181b;

  /* PJ/Bold/21px */
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1.3125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem; /* 152.381% */
}

.active {
  height: fit; /* Set the expanded height */
}

.accordion-content p {
  color: #52525b;

  /* PJ/Regular/18px */
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
  padding: 0 2.44rem 1.62rem;

}


